import { render, staticRenderFns } from "./BettingOfferEvent.vue?vue&type=template&id=1b630aa8&scoped=true"
import script from "./BettingOfferEvent.vue?vue&type=script&lang=js"
export * from "./BettingOfferEvent.vue?vue&type=script&lang=js"
import style0 from "./BettingOfferEvent.vue?vue&type=style&index=0&id=1b630aa8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b630aa8",
  null
  
)

export default component.exports