export default {
  leastDrawn: 'Le moins tiré',
  mostDrawn: 'Le plus tiré',
  leastPlayed: 'Le moins joué',
  mostPlayed: 'Le plus joué',
  drawFrom: 'Tirage de',
  h: 'h',
  startingIn: 'Commence dans',
  waitingForDrawnFrom: 'En attente du tirage de',
  displayingDrawFrom: 'Affichage du tirage de',
  lotteryDraw: 'Tirage au sort',
  eventId: 'ID de l’événement',
  bettingShortcuts: 'Raccourcis de paris',
  draw: 'Tirage',
  next5Events: 'Prochains {value} événements',
  events: 'Événements',
  schedule: 'Programme',
  next: 'Suivant',
  event: 'Événement',
  later: 'Plus tard',
  statistics: 'Statistiques',
  lastnRounds: 'Derniers {value} tours',
  bettingOffer: 'Offre de paris',
  notDisplayed: 'Non affiché',
  latestResults: 'Derniers résultats',
  drawnAt: 'Tiré à {value}',
  additionalBetting: 'Paris supplémentaires',
  drawSumEvenOdd: 'Somme des tirages Pair ou Impair',
  drawSumTotal: 'Total de la somme des tirages',
  drawSumRange: 'Plage de la somme des tirages',
  waitingForDrawNo: 'En attente du tirage No.{value}',
  nextDraw: 'Prochain tirage',
  waitingForDraw: 'En attente du tirage',
  drawCompleted: 'Tirage terminé',
  drawDisplayTemporarilyNotAvailable: 'Affichage du tirage temporairement indisponible',
  connectionError: 'Erreur de connexion !',
  reconnecting: 'Reconnexion...',
  number: 'Numéro',
  headerNumberDescriptionUp: 'Événement',
  headerNumberDescriptionDown: 'Numéro',
};
