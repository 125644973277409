<template>
  <div class="new-counter">
    <div class="title">
      <span>{{getTitle}}</span>
      <span>{{ `${$store.getters.translate('startingIn')}:` }}</span>
    </div>
    <div class="circle"></div>
    <transition name="fade">
      <div class="number"
           v-if="transition">
        {{number}}
      </div>
    </transition>
  </div>
</template>

<script>
import { formatDate } from '@/utility';

export default {
  name: 'NewDrawnCounter',
  data() {
    return {
      number: 6,
      transition: false,
    };
  },
  computed: {
    getTitle() {
      const time = formatDate(
        this.$store.getters.eventTime,
        'HH:mm',
        this.$store.getters.timeZone,
      );
      return `${this.$store.getters.translate('drawFrom')} ${time}h`;
    },
  },
  methods: {
    setCounter() {
      setTimeout(() => {
        this.transition = !this.transition;
        if (this.transition) {
          this.number -= 1;
        }
        this.setCounter();
      }, 500);
    },
  },
  mounted() {
    this.setCounter();
  },
};
</script>

<style lang="scss" scoped>
  .new-counter {
    left: 0;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 100%;
    .title {
      color: #fff;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1.5;
      padding-top: 30px;
      text-align: center;
    }
    .circle {
      animation: zoomIn 1s infinite linear;
      background: #D8D8D8;
      border-radius: 50%;
      bottom: 0;
      left: 0;
      height: 162px;
      margin: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: none;
      width: 162px;
      @media screen and (min-width: 730px) {
        height: 210px;
        width: 210px;
      }
    }
    .number {
      color: #fff;
      font-size: 72px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @keyframes zoomIn {
    from {
      opacity: 0;
    }

    100% {
      opacity: .1;
      -webkit-transform: scale3d(2, 2, 2);
      transform: scale3d(2, 2, 2);
    }
  }
</style>
